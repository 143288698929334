<script>
import { mapGetters } from "vuex";
import CustomerService from "@/services/CustomerService";
import ProjectService from "@/services/ProjectService";
import QregService from "@/services/QregService";

export default {
    name: "VQuickRegForm",
    data() {
        return {
            loading: false,
            internalLoading: false,
            errors: null,
            qreg: {
                customer_id: null,
                project_id: null,
            },
            internal_customer: false,
            customers: [],
            projects: [],
            canSubmit: false,
        };
    },
    computed: {
        inputCustomer: function () {
            return this.qreg.customer_id;
        },
    },
    watch: {
        inputCustomer: async function (newVal) {
            // Fetch projects for a given customer
            if (newVal > 0) {
                // Fetch projects for a newly selected customer
                // This method also fetches internal_customer flag value
                await this.fetchProjects(this.qreg.customer_id);
                // Reset
                this.qreg.project_id = null;
                if (this.projects.length) {
                    this.canSubmit = true;
                } else {
                    this.canSubmit = false;
                }
            }
        },
    },
    async created() {
        await this.fetchCustomers(false);
    },
    methods: {
        async saveForm() {
            try {
                const r = await QregService.POST(this.qreg);
                this.$notify({
                    message: this.$t("qreg.quick_registration_created"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success",
                });

                this.$emit("save", r.data.data);
            } catch (e) {
                console.log("Error saveForm", e);
                this.errors = e.response.data.errors;
            }
        },
        /**
         * Get customer dropdown
         */
        async fetchCustomers(loading = true) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        /**
         * Fetch projects for a given customer and update internal_customer
         * flag
         */
        async fetchProjects(customer_id) {
            try {
                this.internalLoading = true;
                this.projects = [];
                const r = await ProjectService.GET_customerProjectsData(
                    customer_id
                );
                this.projects = r.data.data.projects;
                this.internal_customer = r.data.data.customer.internal_customer;
            } catch (e) {
                console.log("fetchProjects & internal customer flag", e);
            }

            this.internalLoading = false;
        },
    },
};
</script>

<template>
    <modal
        :loading="loading"
        :show="true"
        @close="$emit('close')"
        class="v-quick-reg-form"
    >
        <template slot="header">
            {{ $t("quickreg.create_quick_registration") }}
        </template>
        <template slot="default" v-if="!loading">
            <p>{{ $t("quickreg.intro_text") }}</p>

            <v-loader :loading="loading" v-if="loading" />

            <FormulateForm
                v-model="qreg"
                @submit="saveForm"
                :errors="errors"
                :debounce="100"
                v-else
            >
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            type="fselect"
                            :options="customers"
                            name="customer_id"
                            :label="$t('qreg.customer')"
                            :placeholder="$t('qreg.placeholder_customer')"
                            :validation-name="$t('qreg.customer')"
                        />
                    </div>
                </div>
                <template v-if="inputCustomer > 0">
                    <div class="row">
                        <div class="col" v-if="projects.length > 0">
                            <FormulateInput
                                type="fselect"
                                :options="projects"
                                name="project_id"
                                :label="$t('qreg.case')"
                                :placeholder="$t('qreg.placeholder_case')"
                                :validation-name="$t('qreg.case')"
                            />
                        </div>
                        <div class="col" v-else-if="internalLoading == false && projects.length == 0">
                            <div class="alert alert-warning">
                                {{ $t("qreg.customer_has_no_projects") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-right">
                            <base-button
                                type="secondary"
                                :outline="true"
                                @click="$emit('close')"
                                >{{ $t("cancel") }}</base-button
                            >
                            <base-button
                                type="primary"
                                :loading="loading"
                                native-type="submit"
                                :disabled="!canSubmit"
                                >{{ $t("save") }}</base-button
                            >
                        </div>
                    </div>
                </template>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>