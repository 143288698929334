<script>
export default {
    name: "HalfDashCard",
    props: {
        title: {
            default: "",
        },
        value: {
            default: "",
        },
        progress: {
            default: 0,
        },
        icon: {
            default: "far fa-star",
        },
        navigate: {
            default: "",
        },
        navis: {
            type: Boolean,
            default: false,
            description:
                "Render absolute font awesome bottom navigation indicator + add extra margin",
        },   
        disableHover: {
            type: Boolean,
            default: false,
        }     
    },
    data() {
        return {
            active: false,
            hover: false,
        };
    },
    created() {
        this.matchRouteActive();
    },
    methods: {
        matchRouteActive() {
            if (this.navigate) {
                console.log("AA", this.navigate.name, this.$route.name);
                if (this.$route.name == this.navigate.name) {
                    this.active = true;
                } else {
                    this.active = false;
                }
            }
        },
        doNavigate() {
            if (this.navigate && !this.active) {
                this.$router.push(this.navigate);
            }
        },
        doHover(value) {
            if (!this.disableHover) {
                this.hover = value;
            }
        }
    },
};
</script>

<template>
    <div
        class="card border-0 dash-card"
        :class="{ 'active bg-gradient-mg': active || hover, 'has-navis' : navis }"
        @mouseenter="doHover(true)"
        @mouseleave="doHover(false)"
    >
        <!-- Card body -->
        <div
            class="card-body"
            :class="{ 'm-click': navigate && !active }"
            @click="doNavigate"
        >
            <div class="row">
                <div class="col">
                    <h5
                        class="card-title mb-0"
                        :class="active ? 'text-white' : 'text-muted'"
                    >
                        {{ title }}
                    </h5>

                    <template v-if="$slots.default">
                        <div class="dash-card__default">
                            <slot name="default"></slot>
                        </div>
                    </template>
                    <template v-else>
                        <span
                            class="h2 font-weight-bold mb-0"
                            :class="{ 'text-white': active }"
                            >{{ value }}</span
                        >
                        
                    </template>
                </div>
                <div
                    class="col-auto dash-card__icon"
                    :class="active ? 'text-white' : ''"
                >
                    <i class="fa-fw" :class="icon"></i>
                </div>
            </div>            
        </div>
        <div class="dash-card__navis" v-if="navis && active">
            <i class="fas fa-caret-up fa-2x"></i>
        </div>
    </div>
</template>

<style lang="scss">
.dash-card {
    position: relative;

    hr.hr {
        border-top: none;
        margin-bottom: 0px;
        margin-top: 14px;
    }

    &.has-navis {
        margin-bottom: 40px;
    }

    &__navis {
        position: absolute;
        bottom: -35px;
        left: 0;
        right: 0;
        color: white;
        margin-left: auto;
        margin-right: auto;
        width: 40px;
        text-align: center;
    }

    &.active {
        //background-color: #3a7374;
        .dash-card__footer, .card-title, span, .dash-card__icon {
            color: white !important;
        }
    }

    &__progress {
        margin-top: 11px;
    }

    & .card-body {
        padding: 18px;
    }

    &__default {
        font-size: 29px;
    }

    &__footer {
        margin-top: 15px;
        margin-bottom: 0px;
        font-size: 12px;
        font-weight: 600;
        color: $primary;
    }

    &__icon {
        font-size: 24px;
    }
}
</style>