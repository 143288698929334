<script>
import { mapGetters } from "vuex";

import HalfDashCard from "@/components/Cards/HalfDashCard";
import QuickRegister from "@/components/Momentum/QuickRegister";
import VQuickRegForm from "@/components/Forms/VQuickRegForm";
import NaviBlock from "@/components/Navigation/NaviBlock";

import QregService from "@/services/QregService";
import TaskService from "@/services/TaskService";

import { EventBus } from "@/libraries/EventBus";

export default {
    name: "VDashboard",
    components: { HalfDashCard, QuickRegister, VQuickRegForm, NaviBlock },
    data() {
        return {
            loading: false,
            loadingQregs: false,
            qregsTabOpen: true,
            addQuickReg: false,
            stats: {},
            qregs: []
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId", "getAuthUser", "getDailyTimeGoal"]),
        avgWk() {
            return this.$options.filters.toTime(this.stats.avg_wk);
        },
        avgMon() {
            return this.$options.filters.toTime(this.stats.avg_mon);
        }
    },
    created() {
        this.fetchData();
        this.fetchQregs();
        EventBus.$on("task-created", this.updateData);
        EventBus.$on("refresh-dashboard", this.updateData);
    },
    beforeDestroy() {
        EventBus.$off("task-created", this.updateData);
        EventBus.$off("refresh-dashboard", this.updateData);
    },
    methods: {
        registerAbsence() {
            EventBus.$emit("register-absence");
        },
        updateData() {
            this.fetchData(false);
        },
        async fetchData(doLoading = true) {
            try {
                this.loading = doLoading;
                const r = await TaskService.GET_dashboardStats();
                this.stats = r.data.data;
            } catch (e) {
                console.log("fetchData exception", e);
            }

            this.loading = false;
        },
        async refreshDashboardMeta() {
            try {
                const r = await TaskService.GET_dashboardStats();
                this.stats = r.data.data;
            } catch (e) {
                console.log("refreshDashboardMeta exception", e);
            }
        },
        async fetchQregs() {
            try {
                this.loadingQregs = true;
                let params = {
                    user_id: this.getMyId,
                    stats: this.getMyId
                };
                const r = await QregService.GET(params);
                this.qregs = r.data.data;
            } catch (e) {
                console.log("fetchQregs exception");
            }
            this.loadingQregs = false;
        },
        refreshQregMeta(qreg) {
            let idx = this.qregs.findIndex(e => {
                return e.id == qreg.id;
            });

            this.$set(this.qregs, idx, qreg);
        },
        ctrlTabs(action) {
            EventBus.$emit("qreg-tabs", action);
            this.qregsTabOpen = !this.qregsTabOpen;
        },
        async newQuickReg(qreg) {
            this.addQuickReg = false;
            await this.fetchQregs();
        },
        async deletedQreg(qreg) {
            console.log("Deleted QREG", qreg);
            let idx = this.qregs.findIndex(e => {
                return e.id == qreg.id;
            });

            this.qregs.splice(idx, 1);
        },
        async qregMoveUp(qreg_id) {
            console.log("MoveUP");
            try {
                const r = await QregService.PUT_moveUp(qreg_id);
                let idx = this.qregs.findIndex(e => {
                    return e.id == qreg_id ? true : false;
                });
                let prev_idx = idx - 1;
                let tmp = this.qregs[prev_idx];

                this.$set(this.qregs, prev_idx, this.qregs[idx]);
                this.$set(this.qregs, idx, tmp);
            } catch (e) {
                console.log("moveUp exception", e);
            }
        },
        async qregMoveDown(qreg_id) {
            console.log("MoveDOWN");
            try {
                const r = await QregService.PUT_moveDown(qreg_id);
                let idx = this.qregs.findIndex(e => {
                    return e.id == qreg_id ? true : false;
                });
                let next_idx = idx + 1;
                let tmp = this.qregs[next_idx];

                this.$set(this.qregs, next_idx, this.qregs[idx]);
                this.$set(this.qregs, idx, tmp);
            } catch (e) {
                console.log("moveDown exception", e);
            }
        }
    }
};
</script>
<template>
    <div class="v-dashboard">
        <base-header class="pb-1" type="">
            <bread-bag>
                <div class="row top-navi-block" v-if="true">
                    <div class="col">
                        <navi-block
                            :navigate="{
                                name: 'StartDashboard'
                            }"
                            :title="$t('dashboard.top_menu_start')"
                        />
                    </div>
                    <div class="col">
                        <navi-block
                            :navigate="{
                                name: 'Dashboard'
                            }"
                            :title="$t('dashboard.top_menu_my_hours')"
                        />
                    </div>
                    <div class="col">
                        <navi-block
                            :navigate="{
                                name: 'DashboardAbsence'
                            }"
                            :title="$t('dashboard.top_menu_absence')"
                        />
                    </div>
                </div>
            </bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12 mb-2 d-flex">
                    <h3>{{ $t("dashboard.my_overview") }}</h3>
                    <router-link
                        class="ml-auto"
                        :to="{ name: 'Dashboard' }"
                        v-if="false"
                        >{{ $t("dashboard.show_details") }}</router-link
                    >
                </div>

                <!-- Four boxes -->
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />
                    <div class="row" v-else>
                        <div class="col-12 col-md">
                            <half-dash-card
                                :title="$t('dashboard.box_hours_today')"
                                :value="stats.time_today | toTime"
                                icon="far fa-clock"
                                :navigate="{ name: 'Dashboard' }"
                            >
                            </half-dash-card>
                        </div>

                        <div class="col-12 col-md">
                            <half-dash-card
                                :title="$t('dashboard.box_hours_this_week')"
                                :value="stats.time_week | toTime"
                                icon="far fa-calendar-week"
                                :navigate="{ name: 'DashboardHoursWeek' }"
                            >
                            </half-dash-card>
                        </div>

                        <div class="col-12 col-md">
                            <half-dash-card
                                :title="$t('dashboard.box_hours_this_month')"
                                :value="stats.time_month | toTime"
                                icon="far fa-calendar-alt"
                                :navigate="{ name: 'DashboardHoursMonth' }"
                            >
                            </half-dash-card>
                        </div>

                        <div class="col-12 col-md">
                            <half-dash-card
                                :title="$t('dashboard.box_open_days')"
                                :value="stats.open_days"
                                icon="far fa-calendar-exclamation"
                                :navigate="{ name: 'DashboardOpenDays' }"
                            >
                            </half-dash-card>
                        </div>
                        <div class="col-12 col-md" v-if="false">
                            <half-dash-card
                                :title="$t('dashboard.box_avg_WK_MON')"
                                icon="far fa-tachometer-average"
                                :navigate="{ name: 'DashboardAverages' }"
                                :value="`${avgWk} | ${avgMon}`"
                            >
                            </half-dash-card>
                        </div>
                    </div>
                </div>
                <!-- .Four boxes -->
            </div>
            <!-- .row -->

            <div class="row d-flex align-items-center">
                <div class="col-sm-6 col-12">
                    <div
                        class="d-flex align-items-center quick-registration-header"
                    >
                        <h3 class="pb-1 mb-3">
                            {{ $t("qreg.quick_registration") }}
                        </h3>
                        <div class="pb-3 ml-3 text-primary">
                            <span @click="addQuickReg = true" class="m-click">{{
                                $t("qreg.add_edit_customer_case")
                            }}</span>
                        </div>
                        <div class="pb-3 ml-3 text-warning">
                            <span @click="registerAbsence" class="m-click">{{
                                $t("qreg.add_absence")
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-12 text-right">
                    <div class="pb-3 ml-3 text-primary" v-if="qregs.length">
                        <span
                            @click="ctrlTabs('close')"
                            class="m-click"
                            v-if="qregsTabOpen"
                            >{{ $t("qreg.close_all") }}</span
                        >
                        <span
                            @click="ctrlTabs('open')"
                            class="m-click"
                            v-else
                            >{{ $t("qreg.open_all") }}</span
                        >
                    </div>
                </div>
            </div>
            <v-loader :loading="loadingQregs" v-if="loadingQregs" />
            <template v-else-if="qregs.length">
                <quick-register
                    v-for="qreg in qregs"
                    :key="qreg.id"
                    :context="qreg"
                    enable-date-sync
                    @refresh-meta="refreshQregMeta"
                    @refresh-dashboard-meta="refreshDashboardMeta"
                    @move-up="qregMoveUp"
                    @move-down="qregMoveDown"
                    @deleted="deletedQreg"
                />
            </template>
            <card v-else>
                {{ $t("qregs.no_quick_registrations") }}
            </card>
        </div>
        <!-- .container-fluid -->
        <portal to="modals">
            <v-quick-reg-form
                v-if="addQuickReg"
                @close="addQuickReg = false"
                @save="newQuickReg"
            />
        </portal>
    </div>
</template>

<style></style>
