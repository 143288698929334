<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import NaviBlock from "@/components/Navigation/NaviBlock";
import HalfDashCard from "@/components/Cards/HalfDashCard";
import VSystemActivityForm from "@/components/Forms/VSystemActivityForm";
import VTaskStatus from "@/components/Task/Widgets/VTaskStatus";
import VFilters from "@/components/Search/VFilters";
import ActivityBag from "@/libraries/ActivityBag";

import TaskService from "@/services/TaskService";
import ActivityService from "@/services/ActivityService";
import DateRangeMixin from "@/mixins/DateRangeMixin";

export default {
    name: "VAbsence",
    components: {
        DateRangePicker,
        VFilters,
        VTaskStatus,
        VSystemActivityForm,
        NaviBlock,
        HalfDashCard
    },
    mixins: [DateRangeMixin],
    computed: mapGetters("auth", [
        "getMyId",
        "getAuthUser",
        "getDailyTimeGoal"
    ]),
    data() {
        return {
            loading: false,
            tasks: [],
            meta: null,
            stats: null,
            showModal: false,
            editContext: null,
            activities: [],
            absence_class: "col-12 col-sm-6 col-md-4"
        };
    },
    computed: {
        acts: function() {
            return ActivityBag.activities;
        }
    },
    async created() {
        this.resetSearch();
        this.$apiEnableWatcher();
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await TaskService.GET_absenceIndex(params);
                this.tasks = r.data.data;
                this.meta = r.data.meta;
                this.stats = this.meta.stats;
                console.log("DA FUCK", r.data);
            } catch (e) {
                console.log("fetchData exception", e);
            }

            this.loading = false;
        },
        async fetchSearchData() {
            try {
                const r = await ActivityService.GET_systemDropdown({
                    include_not_work: 1
                });
                this.activities = r.data.data;
            } catch (e) {
                console.log("fetchActivities exception", e);
            }
        },
        resetSearch() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null
                }
            };
            this.apiPaginator = {
                direction: "desc",
                limit: null,
                page: null,
                sort: "Tasks.task_date"
            };
        },
        editTask(task) {
            this.editContext = task;
            this.showModal = true;
        },
        taskSaved(task) {
            let idx = this.tasks.findIndex(t => {
                return task.id == t.id;
            });

            this.$set(this.tasks, idx, task);
            console.log("El fuck", task, idx);
            this.showModal = false;
        },
        async handleDeleteTask(task) {
            this.$swal({
                icon: "warning",
                text: this.$t("task.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    let result = await this.deleteTask(task.id);
                    if (result) {
                        this.$notify({
                            message: this.$t("task.notify_system_task_deleted"),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });

                        this.fetchData();
                    }
                }
            });
        },
        /**
         * Delete the task
         * TBD TODO
         */
        async deleteTask(task_id) {
            // RUN DELETE ACTION
            // Find the task
            try {
                this.loading = true;
                const r = await TaskService.DELETE(task_id);
                return true;
            } catch (e) {
                console.log(e);
                if ("errors" in e.response.data) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: map(e.response.data.errors, n => {
                            return n.join(".\n");
                        })
                    });
                } else {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: this.$t("unknown_error")
                    });
                }
            }

            return false;
        }
    }
};
</script>
<template>
    <div class="v-task-viewer">
        <base-header class="pb-1" type=""
            ><bread-bag>
                <div class="row top-navi-block" v-if="true">
                    <div class="col">
                        <navi-block
                            :navigate="{
                                name: 'StartDashboard'
                            }"
                            :title="$t('dashboard.top_menu_start')"
                        />
                    </div>
                    <div class="col">
                        <navi-block
                            :navigate="{
                                name: 'Dashboard'
                            }"
                            :title="$t('dashboard.top_menu_my_hours')"
                        />
                    </div>
                    <div class="col">
                        <navi-block
                            :navigate="{
                                name: 'DashboardAbsence'
                            }"
                            :title="$t('dashboard.top_menu_absence')"
                        />
                    </div></div></bread-bag
        ></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12" v-if="true">
                    <v-loader :loading="loading" v-if="loading" />
                    <!-- Stats -->
                    <template v-else>
                        <div class="row">
                            <div :class="absence_class">
                                <half-dash-card
                                    :title="$t('tasks.absence')"
                                    :value="
                                        stats.sys_absence.time_reg | toTime
                                    "
                                    :icon="`far ${acts.sys_absence.icon}`"
                                    :disable-hover="true"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div :class="absence_class">
                                <half-dash-card
                                    :title="$t('tasks.sickness')"
                                    :value="
                                        stats.sys_sickness.time_reg | toTime
                                    "
                                    :icon="`far ${acts.sys_sickness.icon}`"
                                    :disable-hover="true"
                                />
                                
                            </div>
                            <div :class="absence_class">
                                <half-dash-card
                                    :title="$t('tasks.doctor_visits')"
                                    :value="stats.sys_doc.time_reg | toTime"
                                    :icon="`far ${acts.sys_doc.icon}`"
                                    :disable-hover="true"
                                />
                            </div>
                            <div :class="absence_class">
                                <half-dash-card
                                    :title="$t('tasks.holidays')"
                                    :value="
                                        stats.sys_holidays.time_reg | toTime
                                    "
                                    :icon="`far ${acts.sys_holidays.icon}`"
                                    :disable-hover="true"
                                />
                            </div>
                            <div :class="absence_class">
                                <half-dash-card
                                    :title="$t('tasks.free_days')"
                                    :value="stats.sys_free.time_reg | toTime"
                                    :icon="`far ${acts.sys_free.icon}`"
                                    :disable-hover="true"
                                />
                            </div>

                            <div :class="absence_class">
                                <half-dash-card
                                    :title="$t('tasks.child_sick')"
                                    :value="
                                        stats.sys_child_sick.time_reg | toTime
                                    "
                                    :icon="`far ${acts.sys_child_sick.icon}`"
                                    :disable-hover="true"
                                />
                            </div>
                            <div :class="absence_class">
                                <half-dash-card
                                    :title="$t('tasks.leave')"
                                    :value="stats.sys_leave.time_reg | toTime"
                                    :icon="`far ${acts.sys_leave.icon}`"
                                    :disable-hover="true"
                                />
                            </div>
                        </div>
                    </template>
                    <!-- .Stats -->
                </div>
                <div class="col-12">
                    <card body-classes="px-0">
                        <!-- V-FILTERS -->
                        <div class="px-4">
                            <v-filters
                                :search="false"
                                :enable-filters="true"
                                @filters-open="fetchSearchData"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <!-- end of right slot -> stats -->
                                <!-- Default slot starts here -> filters -->
                                <div class="col-12 col-md-4">
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="apiSearchFilter.dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange(
                                                        'apiSearchFilter',
                                                        'dateRange'
                                                    )
                                                "
                                            />
                                        </template>
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                </div>
                                <!-- daterange -->
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.activity_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_activity'
                                            )
                                        "
                                        type="select"
                                        :options="activities"
                                    />
                                </div>
                            </v-filters>
                        </div>

                        <!-- .V-FILTERS -->
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="tasks.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg">
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><i class="far fa-lock-open"></i
                                            ></v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    name="Tasks.task_date"
                                                    >{{
                                                        $t("task.date")
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    name="Activities.name"
                                                    >{{
                                                        $t("task.activity")
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th class="text-right">
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    name="Tasks.time_reg"
                                                    >{{
                                                        $t("task.time")
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="task in tasks"
                                            :key="task.id"
                                        >
                                            <v-td>
                                                <v-task-status
                                                    :status="task.status"
                                                />
                                            </v-td>
                                            <v-td>{{
                                                $d(
                                                    new Date(task.task_date),
                                                    "short"
                                                )
                                            }}</v-td>
                                            <v-td
                                                ><i
                                                    class="far fa-fw mr-3"
                                                    :class="
                                                        acts[
                                                            task.activity
                                                                .system_function
                                                        ]['icon']
                                                    "
                                                ></i>
                                                {{ task.activity.name }}</v-td
                                            >
                                            <v-td class="text-right">{{
                                                task.time_reg | toTime
                                            }}</v-td>
                                            <v-td class="text-right">
                                                <base-dropdown
                                                    :menu-on-right="true"
                                                    :has-toggle="false"
                                                    v-if="
                                                        task.is_closed == 0 &&
                                                            task.activity
                                                                .system_function !=
                                                                'sys_not_work'
                                                    "
                                                >
                                                    <template
                                                        slot="title-container"
                                                        ><i
                                                            class="ml-3 far fa-ellipsis-h fa-fw"
                                                        ></i
                                                    ></template>
                                                    <div
                                                        class="dropdown-item"
                                                        @click="editTask(task)"
                                                    >
                                                        <i
                                                            class="far fa-fw fa-pencil"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "task.action_open_modal"
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        class="dropdown-item text-danger"
                                                        @click="
                                                            handleDeleteTask(
                                                                task
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="far fa-fw fa-trash"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "task.action_delete"
                                                            )
                                                        }}
                                                    </div>
                                                </base-dropdown>
                                                <i
                                                    class="far fa-ban"
                                                    v-else
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="d-flex justify-content-center mt-4">
                                <div class="paginator">
                                    <base-pagination
                                        :page-count="
                                            meta.pagination.total_pages
                                        "
                                        :per-page="meta.pagination.per_page"
                                        :value="meta.pagination.current_page"
                                        @input="$handlePagination($event)"
                                    />
                                </div>
                            </div>
                        </template>
                        <div class="px-4" v-else>
                            <div class="alert alert-warning">
                                {{ $t("task.no_absence_tasks_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-system-activity-form
                v-if="showModal"
                :context="editContext"
                @close="showModal = false"
                @save="taskSaved"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.colmd {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}
</style>
