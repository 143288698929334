<script>
/**
 * Quick register used on dash
 *
 * SCSS: _dashboard.scss
 */
import { mapGetters } from "vuex";
import VQuickTask from "@/components/Projects/VQuickTask";
import VTaskTable from "@/components/Task/VTaskTable";
import TaskService from "@/services/TaskService";
import QregService from "@/services/QregService";
import { EventBus } from "@/libraries/EventBus";

export default {
    name: "QuickRegister",
    props: {
        context: {
            description: "Qreg object with loaded relations",
        },
        enableDateSync: {
            type: Boolean,
            default: false,
            description: "Whether to enable bus-based datepicker sync among all qregs"
        }
    },
    components: { VQuickTask, VTaskTable },
    data() {
        return {
            loading: false,
            tasks: [],
            tasksLoaded: false,
            tasksOpen: true,
            open: true,
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        no_project: function () {
            if (this.context.project_id == null) {
                return true;
            } else {
                return false;
            }
        },
        time_today: function () {
            return this.context.stats.today_time_reg;
        },
        time_week: function () {
            return this.context.stats.week_time_reg;
        },
        canCreateTasks: function () {
            if (this.no_project) {
                return true;
            } else {
                return this.context.project.status == 5 ? true : false;
            }
        },
    },
    created() {
        EventBus.$on("qreg-tabs", this.handleTab);
    },
    methods: {
        handleTab(action) {
            if (action == "open") {
                this.open = true;
            } else {
                this.open = false;
            }
        },
        async loadTasks() {
            if (!this.tasksLoaded) {
                this.tasksOpen = true;
                await this.fetchTasks();
                this.tasksLoaded = true;
            } else {
                this.tasksOpen = !this.tasksOpen;
            }
        },
        getParams() {
            let params = {
                user_id: this.getMyId,
                sort: "Tasks.task_date",
                direction: "desc",
                limit: 5,
            };

            if (this.no_project) {
                params["customer_id"] = this.context.customer_id;
                params["internal_time"] = 1;
            } else {
                params["project_id"] = this.context.project_id;
            }

            return params;
        },
        async fetchTasks() {
            try {
                this.loading = true;
                let params = this.getParams();
                const r = await TaskService.GET(params);
                this.tasks = r.data.data;
            } catch (e) {
                console.log("Fetch tasks");
            }

            this.loading = false;
        },
        async refreshQregMeta() {
            try {
                let params = {
                    user_id: this.getMyId,
                    stats: this.getMyId,
                };
                const r = await QregService.GETS(this.context.id, params);
                this.$emit("refresh-meta", r.data.data);
                this.$emit("refresh-dashboard-meta");
            } catch (e) {
                console.log("fetchQregs exception");
            }
        },
        /**
         * New task created
         */
        async quickTaskCreated(task) {
            this.refreshQregMeta();

            if (!this.tasksLoaded) {
                await this.loadTasks();
            } else {
                this.tasksOpen = true;
                this.tasks.splice(0, 0, task);
            }

            this.$emit("refresh-dashboard-meta");
        },
        async handleDeleteQreg() {
            this.$swal({
                icon: "warning",
                text: this.$t("qreg.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true,
            }).then(async (confirmed) => {
                if (confirmed.isConfirmed) {
                    await this.deleteQreg();
                    this.$emit("deleted", this.context);
                }
            });
        },
        async deleteQreg() {
            try {
                const r = await QregService.DELETE(this.context.id);
            } catch (e) {
                if ("errors" in e.response.data) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: map(e.response.data.errors, (n) => {
                            return n.join(".\n");
                        }),
                    });
                } else {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: this.$t("unknown_error"),
                    });
                }
            }
        },
    },
};
</script>

<template>
    <div class="v-quick-reg">
        <card
            body-classes="px-0"
            custom-header-classes="v-quick-reg__card"
            custom-footer-classes="v-quick-reg__footer"
            :no-body="!open"
        >
            <template slot="customHeader">
                <div class="v-quick-reg__header">
                    <div class="v-quick-reg__wrapper">
                        <div class="v-quick-reg__customer">
                            <div class="data-label">
                                {{ $t("qreg.customer") }}
                            </div>
                            <div class="data-value">
                                {{ context.customer.name }}
                            </div>
                        </div>
                        <div class="v-quick-reg__project">
                            <div class="data-label">
                                {{ $t("qreg.project") }}
                            </div>
                            <div class="data-value">
                                <router-link
                                    :to="{
                                        name: 'CustomersTabInternalTime',
                                        params: {
                                            customer_id: context.customer_id,
                                        },
                                    }"
                                    v-if="no_project"
                                    >{{
                                        $t("qregs.internal_time")
                                    }}</router-link
                                >
                                <router-link
                                    :to="{
                                        name: 'ProjectViewTasks',
                                        params: {
                                            project_id: context.project_id,
                                        },
                                    }"
                                    v-else
                                >
                                    {{ context.project.title }}
                                </router-link>
                            </div>
                        </div>
                        <div class="v-quick-reg__status">
                            <div class="data-label">
                                {{ $t("qreg.status") }}
                            </div>
                            <div class="data-value">
                                <template v-if="no_project"> --- </template>
                                <template v-else>
                                    {{ context.project.status_name }}
                                </template>
                            </div>
                        </div>
                        <div class="v-quick-reg__time">
                            <div class="data-label">
                                {{ $t("qreg.time_today") }}
                            </div>
                            <div class="data-value __time">
                                {{ time_today | toTime }}
                            </div>
                        </div>
                        <div class="v-quick-reg__time">
                            <div class="data-label">
                                {{ $t("qreg.time_this_week") }}
                            </div>
                            <div class="data-value __time">
                                {{ time_week | toTime }}
                            </div>
                        </div>
                        <div class="v-quick-reg__menu">
                            <base-dropdown
                                :menu-on-right="true"
                                :has-toggle="false"
                            >
                                <template slot="title-container"
                                    ><i
                                        class="far fa-ellipsis-h fa-fw fa-lg"
                                    ></i
                                ></template>
                                <div
                                    class="dropdown-item"
                                    v-if="no_project"
                                    @click="
                                        $router.push({
                                            name: 'CustomersTabInternalTime',
                                            params: {
                                                customer_id:
                                                    context.customer_id,
                                            },
                                        })
                                    "
                                >
                                    <i class="far fa-fw fa-building"></i>
                                    {{
                                        $t(
                                            "qreg.action_go_to_internal_time_listing"
                                        )
                                    }}
                                </div>
                                <div
                                    class="dropdown-item"
                                    v-else
                                    @click="
                                        $router.push({
                                            name: 'ProjectViewTasks',
                                            params: {
                                                project_id: context.project_id,
                                            },
                                        })
                                    "
                                >
                                    <i
                                        class="far fa-fw fa-external-link-alt"
                                    ></i>
                                    {{ $t("qreg.action_go_to_case") }}
                                </div>
                                <div
                                    class="dropdown-item"
                                    @click="$emit('move-up', context.id)"
                                >
                                    <i
                                        class="far fa-fw fa-arrow-up m-click"
                                    ></i>
                                    {{ $t("qreg.action_move_up") }}
                                </div>
                                <div
                                    class="dropdown-item"
                                    @click="$emit('move-down', context.id)"
                                >
                                    <i class="far fa-fw fa-arrow-down"></i>
                                    {{ $t("qreg.action_move_down") }}
                                </div>
                                <div
                                    class="dropdown-item text-danger"
                                    @click="handleDeleteQreg"
                                >
                                    <i class="far fa-fw fa-trash"></i>
                                    {{
                                        $t(
                                            "qreg.action_delete_from_quick_register"
                                        )
                                    }}
                                </div>
                            </base-dropdown>
                        </div>
                        <div class="v-quick-reg__ctrl">
                            <i
                                class="far fa-fw fa-lg m-click"
                                :class="{
                                    'fa-chevron-down': open,
                                    'fa-chevron-up': !open,
                                }"
                                @click="open = !open"
                            ></i>
                        </div>
                    </div>
                </div>
            </template>

            <template slot="default" v-if="open">
                <div class="row">
                    <div class="col-12">
                        <v-quick-task
                            :enable-date-sync="enableDateSync"
                            :customer-id="context.customer_id"
                            :internal-customer="
                                context.customer.internal_customer == 1
                                    ? true
                                    : false
                            "
                            :allow-create="canCreateTasks"
                            :project-id="
                                context.project_id ? context.project_id : 0
                            "
                            class="in-quick-reg"
                            @save="quickTaskCreated"
                        />
                    </div>
                </div>
                <v-loader :loading="loading" v-if="loading" />
                <div class="row" v-else-if="tasksLoaded">
                    <div class="col-12" v-if="tasksOpen">
                        <v-task-table
                            :tasks="tasks"
                            :show-date="true"
                            @refresh-meta="refreshQregMeta"
                        />
                    </div>
                </div>
            </template>

            <template slot="customFooter" v-if="open">
                <div class="v-quick-reg__footer--inner">
                    <i
                        @click="loadTasks"
                        class="fas fa-fw fa-md m-click"
                        :class="
                            tasksOpen && tasksLoaded
                                ? 'fa-caret-up'
                                : 'fa-caret-down'
                        "
                    ></i>
                </div>
            </template>
        </card>
    </div>
</template>

<style>
</style>
