var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-quick-reg"},[_c('card',{attrs:{"body-classes":"px-0","custom-header-classes":"v-quick-reg__card","custom-footer-classes":"v-quick-reg__footer","no-body":!_vm.open}},[_c('template',{slot:"customHeader"},[_c('div',{staticClass:"v-quick-reg__header"},[_c('div',{staticClass:"v-quick-reg__wrapper"},[_c('div',{staticClass:"v-quick-reg__customer"},[_c('div',{staticClass:"data-label"},[_vm._v(" "+_vm._s(_vm.$t("qreg.customer"))+" ")]),_c('div',{staticClass:"data-value"},[_vm._v(" "+_vm._s(_vm.context.customer.name)+" ")])]),_c('div',{staticClass:"v-quick-reg__project"},[_c('div',{staticClass:"data-label"},[_vm._v(" "+_vm._s(_vm.$t("qreg.project"))+" ")]),_c('div',{staticClass:"data-value"},[(_vm.no_project)?_c('router-link',{attrs:{"to":{
                                    name: 'CustomersTabInternalTime',
                                    params: {
                                        customer_id: _vm.context.customer_id,
                                    },
                                }}},[_vm._v(_vm._s(_vm.$t("qregs.internal_time")))]):_c('router-link',{attrs:{"to":{
                                    name: 'ProjectViewTasks',
                                    params: {
                                        project_id: _vm.context.project_id,
                                    },
                                }}},[_vm._v(" "+_vm._s(_vm.context.project.title)+" ")])],1)]),_c('div',{staticClass:"v-quick-reg__status"},[_c('div',{staticClass:"data-label"},[_vm._v(" "+_vm._s(_vm.$t("qreg.status"))+" ")]),_c('div',{staticClass:"data-value"},[(_vm.no_project)?[_vm._v(" --- ")]:[_vm._v(" "+_vm._s(_vm.context.project.status_name)+" ")]],2)]),_c('div',{staticClass:"v-quick-reg__time"},[_c('div',{staticClass:"data-label"},[_vm._v(" "+_vm._s(_vm.$t("qreg.time_today"))+" ")]),_c('div',{staticClass:"data-value __time"},[_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.time_today))+" ")])]),_c('div',{staticClass:"v-quick-reg__time"},[_c('div',{staticClass:"data-label"},[_vm._v(" "+_vm._s(_vm.$t("qreg.time_this_week"))+" ")]),_c('div',{staticClass:"data-value __time"},[_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.time_week))+" ")])]),_c('div',{staticClass:"v-quick-reg__menu"},[_c('base-dropdown',{attrs:{"menu-on-right":true,"has-toggle":false}},[_c('template',{slot:"title-container"},[_c('i',{staticClass:"far fa-ellipsis-h fa-fw fa-lg"})]),(_vm.no_project)?_c('div',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({
                                        name: 'CustomersTabInternalTime',
                                        params: {
                                            customer_id:
                                                _vm.context.customer_id,
                                        },
                                    })}}},[_c('i',{staticClass:"far fa-fw fa-building"}),_vm._v(" "+_vm._s(_vm.$t( "qreg.action_go_to_internal_time_listing" ))+" ")]):_c('div',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({
                                        name: 'ProjectViewTasks',
                                        params: {
                                            project_id: _vm.context.project_id,
                                        },
                                    })}}},[_c('i',{staticClass:"far fa-fw fa-external-link-alt"}),_vm._v(" "+_vm._s(_vm.$t("qreg.action_go_to_case"))+" ")]),_c('div',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$emit('move-up', _vm.context.id)}}},[_c('i',{staticClass:"far fa-fw fa-arrow-up m-click"}),_vm._v(" "+_vm._s(_vm.$t("qreg.action_move_up"))+" ")]),_c('div',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$emit('move-down', _vm.context.id)}}},[_c('i',{staticClass:"far fa-fw fa-arrow-down"}),_vm._v(" "+_vm._s(_vm.$t("qreg.action_move_down"))+" ")]),_c('div',{staticClass:"dropdown-item text-danger",on:{"click":_vm.handleDeleteQreg}},[_c('i',{staticClass:"far fa-fw fa-trash"}),_vm._v(" "+_vm._s(_vm.$t( "qreg.action_delete_from_quick_register" ))+" ")])],2)],1),_c('div',{staticClass:"v-quick-reg__ctrl"},[_c('i',{staticClass:"far fa-fw fa-lg m-click",class:{
                                'fa-chevron-down': _vm.open,
                                'fa-chevron-up': !_vm.open,
                            },on:{"click":function($event){_vm.open = !_vm.open}}})])])])]),(_vm.open)?_c('template',{slot:"default"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-quick-task',{staticClass:"in-quick-reg",attrs:{"enable-date-sync":_vm.enableDateSync,"customer-id":_vm.context.customer_id,"internal-customer":_vm.context.customer.internal_customer == 1
                                ? true
                                : false,"allow-create":_vm.canCreateTasks,"project-id":_vm.context.project_id ? _vm.context.project_id : 0},on:{"save":_vm.quickTaskCreated}})],1)]),(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):(_vm.tasksLoaded)?_c('div',{staticClass:"row"},[(_vm.tasksOpen)?_c('div',{staticClass:"col-12"},[_c('v-task-table',{attrs:{"tasks":_vm.tasks,"show-date":true},on:{"refresh-meta":_vm.refreshQregMeta}})],1):_vm._e()]):_vm._e()],1):_vm._e(),(_vm.open)?_c('template',{slot:"customFooter"},[_c('div',{staticClass:"v-quick-reg__footer--inner"},[_c('i',{staticClass:"fas fa-fw fa-md m-click",class:_vm.tasksOpen && _vm.tasksLoaded
                            ? 'fa-caret-up'
                            : 'fa-caret-down',on:{"click":_vm.loadTasks}})])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }